/* calendar */
.full-calendar {
    margin-top: 40px;
}



/* calendar rows */
.fc-scrollgrid tr{
    background-color: white;
}

.fc-daygrid-dot-event {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    float: left !important;
}


/* calendar headers with the day of week */
.fc-col-header th{
    background-color: #EDEDED;
}

/* calendar event (parent of .event) */
.fc-event-main {
    /* overflow-y: scroll; */
    display: flex;
    padding: 0 !important;
}

/* month calendar cells */
.fc-daygrid-day-events {
    /* overflow-y: scroll; */
    max-height: 100px;
}

/* month calendar days */
.fc-daygrid-day {
    overflow-y: hidden !important; /*fixes issue w/ overlapping events*/
    overflow-x: hidden !important; /*fixes issue w/ overlapping events*/
    overflow-wrap: normal !important;
}

/* top left cell of day/week calendar (above times and left of days) */
th.fc-timegrid-axis {
    background-color: white;
}

.fc .fc-timegrid-now-indicator-arrow {
    border-color: var(--fc-now-indicator-color, pink);
} 

/* shift */
.event {
    padding-left: 5px;
    background-color: '#EDEDED';
}

/* sidebar with checkboxes to display/take away groups */
.sidebar {
    margin-top: 5%;
}

/* checkboxes in sidebar */
.group-box {
    height: 20px;
    width: 20px;
    float: left;
}

/* links: used for full calendar headers (day of week), numbered day of month, and month events */
a { 
    color: inherit; 
}

/* lowercase */
.lower {
    text-transform: lowercase;
}

.selected-event {
  /* hook-em horns! */
  background-color: #CC5500;
}

.fc-timegrid-event-harness:hover {
    z-index: 4 !important;
    left: 0% !important;
    box-shadow: 0.5px 0.5px 10px grey;
}