.loader {
    width: 60px;
    height: 60px;
    top: 20%;
    left: 45%;
    border: 10px solid lightgrey;
    border-bottom-color: hsl(217, 71%, 53%);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

#pdf-viewer {
    margin: 0 auto;
    width: 100%;
    height: 850px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}