.fc-event.non-draggable {
    cursor: not-allowed;
    /* Show a "disabled" cursor */
    pointer-events: none;
    /* Disable interaction */
}

.highlighted-shift {
    background-color: orange !important;
    border-color: orange !important;
}

.card {
    overflow: visible;
}

/* TO REMOVE AFTER MERGING REWORK_UI_2024*/

/* Tooltip container */
.tooltipped-button {
    position: relative;
    /* z-index:10; */
}

.tooltipped-button .tooltiptext {
    visibility: hidden;
    width: max-content;
    max-width: 400px;
    background-color: #233038;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 48px;

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipped-button:hover .tooltiptext {
    visibility: visible;
}

.tooltipped-button .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.legend {
    display: grid;
    gap: 16px;
    align-content: start;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.legend h4 {
    font-size: 16px;
    font-weight: 600;
}

.legend .flex-holder {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
}

.legend .flex-holder>div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.square {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.square.grey {
    background-color: grey;
}

.square.blue {
    background-color: #3788d8;
}

.square.orange {
    background-color: #CC5500;
}

.square.green {
    background-color: rgb(34, 139, 34);
}

.square.yellow {
    background-color: rgb(218, 165, 32);
}