@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.1/css/bulma.min.css);
@import url(https://cdn.datatables.net/1.11.1/css/dataTables.bulma.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* LOGIN THINGS */
.login_header {
  text-align: center;
  color:	hsl(217, 71%, 53%);
  font-weight: 700;
  font-size: 30px;
  margin: 50px;
  text-shadow: 0.1px 0.1px 1px rgba(149, 157, 165, 0.2);
}

.login-background {
  background-color:hsl(217, 71%, 53%);
  height: 100vh;
}

.login-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 50px;
  padding: 20px;
  height: 300px;
  width: 45%;
  margin: auto;
  background-color:white;
  border-radius: 7px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.login-button {
  background-color: hsl(217, 71%, 53%);
  color: white;
  padding: .8em 2em;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-shadow: 0.1px 0.1px 5px rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 5px 10px;
}

/* NAVBAR THINGS */
.logo {
	float: left;
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 10px;
  cursor: pointer;
}

nav {
  box-shadow: 0.5px 0.5px 1px lightgrey;
}

/* LANDING PAGE THINGS */

.background-pretty-gradient {
	display: inline-block;
  /* background-image: url('beach.jpeg'); */
	background-color: hsl(217, 71%, 53%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left;
  width: 100%;
  /* padding-bottom: 3em; */
  padding: 1em;
  justify-content: center;  /*x-axis*/
  align-items: center;      /*y-axis*/
}

.greeting {
  padding: 3%;
  /* padding-left: 10%;
  padding-right: 10%; */
  /* padding-top: 1em; */
  /* padding-top: 2%; */
  margin-left: 3%;
	font-family: 'Roboto';
	font-size: 40px;
	font-weight: 300;
	color: white;
  text-align: left;
}

.landing-box {
  /* padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 2%; */
	font-size: 40px;
	color: white;
	font-family: 'Roboto';
	font-weight: 400;
  text-align: left;
}

.transparent-box {
  background: rgba(255, 255, 255, 0.22);
	color: white;
	margin-left: 10%;
  margin-top: 20px;
  padding: 25px;
  display: inline-block;
  align-items: center;
  text-align: left;
}

.tab {
  	margin-left: 10%;
}

.no-shifts {
  font-size: 35px;
  text-align: center;
}

.clock-in {
  background-color: white;
  color: #81a0e8;
  border: none;
  padding: 10px 20px 10px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  display: inline-block;
  margin-left: 10%;
  margin-top: 20px;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.clock{
  display: inline-block;
  height: 30px;
  width: 40px;
}

.clock-text {
  padding-left: 10px;
  font-size: 25px;
}

.shift-time {
  font-size: 30px;
}

.pm {
  font-size: 24px;
  color: white;
}

.shift-location {
  font-size: 18px;
  margin-bottom: 9px;
}

.shift-role {
  font-size: 18px;
}

.upcoming-shift {
	display: inline-block;
  width: 100%;
}

.upcoming-shift-title {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  font-size: 2.5em;
  font-family: Roboto;
  font-weight: normal;
}

.shift-pool {
  display: inline-block;
  width: 100%;
  padding-bottom: 3em;
  /* margin-top: 30px; */
}

.shift-pool-title {
  /* margin-top: 30px; */
  font-size: 2.5em;
  font-family: Roboto;
  font-weight: normal;
  position: relative;
}

.shift-pool-image {
  height: 3em;
  padding-left: 10%;
  margin-right: .5em;
}

.open-shift-time {
  font-family: Roboto;
  font-size: 1em;
  font-weight: bold;
  margin: 0 .3em .3em 8em;
}


.tile.is-vertical>.tile.is-child:not(:last-child) {
  margin-bottom: 0!important;
}
.tile.is-vertical>.tile.is-child:not(:last-child) {
  margin-bottom: 0!important;
}

.open-shift-text {
  font-family: Roboto;
  font-size: 1em;
  margin: 0 .5em .5em 8em;
}

.open-shift-button {
  margin-top: 5px;
  width: 80px;
}

.upcoming-shift-date {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
}

.upcoming-shift-date > p {
  text-align: center;
  color: darkgray;
}

.upcoming-shift-time {
  font-family: Roboto;
  font-size: 1em;
  font-weight: bold;
  margin-top: .1em;
  margin-bottom: .1em;
}

.upcoming-shift-text {
  font-family: Roboto;
  font-size: 1em;
  margin-top: .1em;
  margin-bottom: .1em;
}

/* AVAILABILITY THINGS */
.week th {
  background-color: #ededed;
  padding: 10px 40px 10px 20px;
  font-size: 12px;
  margin-right: 80px;
}
.time td {
	background-color:  #EDEDED;
	padding: 10px 40px 550px 20px;
	font-size: 12px;
}

.key-margin {
  margin-left: 3%;
}

.form {
  background-color: #EDEDED;

  margin-right: 80%;
  margin-left: 5%;
}

.trash {
  float: right;
  cursor: pointer;
}

.actions-divider {
  float: right;
  margin: 0 10px;
  border-left: 2px solid;
  height: 18px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  z-index: 10;
}

/* SCHEDULE THINGS */
.days-of-week {
  float: right;
  border-spacing: 40px 20px;
  margin-right: 80px;
}

.dwm {
  float: right;
  border-collapse: collapse;
  margin-right: 120px;
}
.day-week-month {
  cursor: pointer;
  border: none ;
  outline: none ;
  width: 6em ;
  padding: 14px 16px ;
  font-size: 17px;
  background-image: radial-gradient(
    104.49% 573.2% at -9.55% 10.53%,
    rgba(245, 115, 123, 0.5) 12.98%,
    rgba(161, 149, 247, 0.5) 52.6%,
    rgba(242, 166, 255, 0.5) 100%
  ) ;
  color: white;

}

.day-week-month.active {
  background-image: radial-gradient(
    104.49% 573.2% at -9.55% 10.53%,
    #f5737b 12.98%,
    #a195f7 52.6%,
    #f2a6ff 100%
  ) ;
  color: white;
}

.view th {
  text-align: center;
  color: white;
  font-size: 16px;
}

.key /* div containing all keys */ {
  position: relative;
  top: 12em;
  left: 6em;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  float: left;
  width: 20%;
  height: 100%
}

.key-box /* colored box */ {
  height: 20px;
  width: 20px;
  float: left;
}

/* THE CHECKBOXES */
/* hide defaults */
.check-container .check-input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark{
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check-container .check-input:checked ~ .checkmark {
  background-color: #2196F3;
}
.checkmark:after {
  content: "";
  display: none;
}
.check-container .check-input:checked ~ .checkmark:after {
  display: block;
}

.check-container .check-input:checked ~ .mps-box{
  background-color: rgba(255, 0, 0, .4);
}
.check-container .check-input:checked ~ .services-box {
  background-color:rgba(245, 66, 170, .4);
}
.check-container .check-input:checked ~ .tutors-box {
  background-color: rgba(10, 12, 266, .4);
}
.check-container .check-input:checked ~ .labTrain-box{
  background-color: rgba(245, 113, 12, .4);
}
.check-container .check-input:checked ~ .officeHours-box{
  background-color: rgba(12, 202, 245, .4);
}
.check-container .check-input:checked ~ .designhub-box {
  background-color: rgba(150, 12, 200, .4);
}
.check-container .check-input:checked ~ .codePlus-box {
  background-color: rgba(255, 255, 0, .4);
}

.schedule-calendar /* box containing calendar */ {
  float: right;
  width: 80%
}

.availability-table td{
  background-color: white;
}
/* HOURS THINGS */
.table {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
  border-bottom: 2px solid hsl(217, 71%, 53%);
}

tr:hover {
   background: #f4f4f4;
}

th {
  background-color: hsl(217, 71%, 53%);
}

.table-head {
  color: white;
}

.hours-header-table th {
  background-color: white;
  border: 0;
}

.hours-table {
  margin-left: 10%;
  margin-right: 10%;
}

#dataTable_paginate {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
  letter-spacing: 1px;
}

.previous, .next {
  color:hsl(217, 71%, 53%);
  padding: 5px;
}

.current {
  color:hsl(217, 71%, 53%);
}

.role-select {
  margin: 0 20px 20px 0;
  /* padding: 0; */
}

.red{
  color: red;
}

.yellow{
  color: yellow;
}

.green{
  color: green;
}

.blue{
  color:hsl(217, 71%, 53%);
}

.space {
  margin: 0 10px;
}

.grace-period {
  position: relative;
  bottom: 20px;
  margin: 0 auto 3rem;
}

.range {
  width: 100%;
}

.show-grace {
  position: absolute;
  background: #03a9f4;
  color: white;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.show-grace::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: #03a9f4;
  top: -1px;
  left: 50%;
}

/* CALENDAR SHIFTS */

.create-open-shifts-title, .new-schedule {
  font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
}

.dot-yellow {
  height: 10px;
  width: 10px;
  background-color: goldenrod;
  border-radius: 50%;
  display: inline-block;
}

.dot-green {
  height: 10px;
  width: 10px;
  background-color: lightgreen;
  border-radius: 50%;
  display: inline-block;
}

.dot-red {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.card.show-overflow {
  overflow: visible;
}

.custom-select-wrapper {
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}
.custom-select {
  display: block;
  font-size: 1em;
  border: #dbdbdb 1px solid;
  border-radius: 4px;
  justify-content: space-between;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  color: #363636;
  cursor: pointer;
}
.custom-options {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  border: #dbdbdb 1px solid;
  background: #fff;
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
  height: 175px;
  overflow-y: scroll;
}
.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.custom-option {
  display: block;
  font-size: 1em;
  justify-content: space-between;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  color: #363636;
  cursor: pointer;
  position: relative;
}
.custom-option:hover {
  background-color: rgba(191, 191, 191, 0.2);
}

/* Flash */
.is-hide{
  visibility: hidden;
}


/* BULMA */

.tile.is-gapless > .tile.is-parent{
  padding: 0;
}

.tile.is-gapless > .tile.is-vertical > .tile.is-child {
  margin-bottom: 0 !important;
}

.schedule-button {
  float: right;
  z-index: 10;
  position: relative;
}

.back-to-schedule-button{
  /* border-radius: 7px; */
  float: right;
  z-index: 10;
  position: relative;
}

.scheduled-shift h4 {
  padding-top: 1em;
  font-weight: 600;
  font-size: 11px;
  color: black;
}

.scheduled-shift p {
  font-size: 10px;
  color: #484848;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.schedule-block {
  float: left;
  width: 100%;
  margin: 20px;

}

.overlap {
  padding-top: 1em;
  font-size: 10px;
  color: #484848;
  word-break: break-all;
  overflow: hidden;
}

.short-overlap {
  font-size: 10px;
  color: #484848;
  overflow: hidden;
}

.overlap h4 {
  font-weight: 600;
  font-size: 11px;
  color: black;
}

.scheduled-shift span {
  font-size: 11px !important;
  font-weight: 600 !important;
  color: black !important;
}

.unscheduled-availability {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.availability-item {
  flex: 0 1 48%;
}

.landing-page-shifts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.landing-page-shift-item{
  flex: 0 1 48%;
}

.landing-employee {
  margin-left: 10%;;
}

.message {
  background-color: white;
  border-style: none;
}

.nav-link:hover {
  color: #3273dc;
  background-color: #fafafa;
  cursor: pointer;
}

.rainbow-gradient {
  background-image: radial-gradient(
    104.49% 573.2% at -9.55% 10.53%,
    #f5737b 12.98%,
    #a195f7 52.6%,
    #f2a6ff 100%
  ) ;
  color: white;
  border-style: none;
  border-radius: 4px;
  text-align: center;
  padding: .5em;
  width: 50%;
}

.right-button {
  cursor: pointer;
  width: 100%;
  margin-top: auto;
}

.parent {
  height: 70vh; /* set height as you need */
  display: flex;
  flex-direction: column;
}

.capitalize-me {
  text-transform: capitalize;
}

.schedule-shift{
  cursor:pointer;
  font-size: 12px;
  padding-left: 1em;
  /* padding-top: 1em; */
  /* padding-bottom: var(--padbottom); */
  background-color: rgba(235, 232, 232, .3);
  border-left: 5px solid var(--tab);
  width: 10em;
}

.is-full-width {
  width: 100.1%;
}

.side-by-side {
  display: flex;
  justify-content: space-between;
}

.left-child {
  flex: 0 1 25%;
}

.right-child {
  flex: 0 1 70%;
}

/* Multiple Select */


/* profile page */

@media only screen and (max-width: 600px) {
  .shift-time {
    font-size: 30px;
  }

  .pm {
    font-size: 20px;
  }

  .login_header {
    top: 25%;
    font-size: 30px;
  }

  .login-container {
    height: 200px;
    width: 300px;
  }
}
.bold {
  font-weight: bold;
}

.loading {
  font-size: 36px;
  color: rgba(161, 149, 247, 52.6%);
  padding-left: 10px;
}

.generated-calendar {
  margin: 20px 0;
}

.remaining-availability {
  margin-top: 80px;
}

.hide {
  display: none;
  margin-bottom: 5px;
}

.hover-undefined:hover + .hide {
  display: block;
}

.fa-question-circle {
  margin-left: 5px;
  font-size: 1.5rem;
}

.second-level {
  list-style-type: circle;
  margin-left: 1em;
 }

 .supervisor-add-time-field {
  position: relative;
}

 .supervisor-add-time-field::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.clickable:hover{
  cursor: pointer;
}

.table-icon{
  margin-left: 2.5px;
  margin-right: 2.5px;
}

/* calendar */
.full-calendar {
    margin-top: 40px;
}



/* calendar rows */
.fc-scrollgrid tr{
    background-color: white;
}

.fc-daygrid-dot-event {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    float: left !important;
}


/* calendar headers with the day of week */
.fc-col-header th{
    background-color: #EDEDED;
}

/* calendar event (parent of .event) */
.fc-event-main {
    /* overflow-y: scroll; */
    display: flex;
    padding: 0 !important;
}

/* month calendar cells */
.fc-daygrid-day-events {
    /* overflow-y: scroll; */
    max-height: 100px;
}

/* month calendar days */
.fc-daygrid-day {
    overflow-y: hidden !important; /*fixes issue w/ overlapping events*/
    overflow-x: hidden !important; /*fixes issue w/ overlapping events*/
    overflow-wrap: normal !important;
}

/* top left cell of day/week calendar (above times and left of days) */
th.fc-timegrid-axis {
    background-color: white;
}

.fc .fc-timegrid-now-indicator-arrow {
    border-color: pink;
    border-color: var(--fc-now-indicator-color, pink);
} 

/* shift */
.event {
    padding-left: 5px;
    background-color: '#EDEDED';
}

/* sidebar with checkboxes to display/take away groups */
.sidebar {
    margin-top: 5%;
}

/* checkboxes in sidebar */
.group-box {
    height: 20px;
    width: 20px;
    float: left;
}

/* links: used for full calendar headers (day of week), numbered day of month, and month events */
a { 
    color: inherit; 
}

/* lowercase */
.lower {
    text-transform: lowercase;
}

.selected-event {
  /* hook-em horns! */
  background-color: #CC5500;
}

.fc-timegrid-event-harness:hover {
    z-index: 4 !important;
    left: 0% !important;
    box-shadow: 0.5px 0.5px 10px grey;
}
.fc-event.non-draggable {
    cursor: not-allowed;
    /* Show a "disabled" cursor */
    pointer-events: none;
    /* Disable interaction */
}

.highlighted-shift {
    background-color: orange !important;
    border-color: orange !important;
}

.card {
    overflow: visible;
}

/* TO REMOVE AFTER MERGING REWORK_UI_2024*/

/* Tooltip container */
.tooltipped-button {
    position: relative;
    /* z-index:10; */
}

.tooltipped-button .tooltiptext {
    visibility: hidden;
    width: -webkit-max-content;
    width: max-content;
    max-width: 400px;
    background-color: #233038;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 48px;

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipped-button:hover .tooltiptext {
    visibility: visible;
}

.tooltipped-button .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.legend {
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    align-content: start;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.legend h4 {
    font-size: 16px;
    font-weight: 600;
}

.legend .flex-holder {
    display: flex;
    grid-gap: 32px;
    gap: 32px;
    flex-wrap: wrap;
}

.legend .flex-holder>div {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.square {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.square.grey {
    background-color: grey;
}

.square.blue {
    background-color: #3788d8;
}

.square.orange {
    background-color: #CC5500;
}

.square.green {
    background-color: rgb(34, 139, 34);
}

.square.yellow {
    background-color: rgb(218, 165, 32);
}


table td
{
overflow: inherit !important;
}
.hide-form {
  display: none;
}

.card {
  overflow: visible;
}

.field.is-grouped:last-child {
  margin-bottom: 0;
}

/* Tooltip container */
.tooltipped-button {
  position: relative;
  /* z-index:10; */
}

/* Tooltip text */
.tooltipped-button .tooltiptext {
  visibility: hidden;
  width: -webkit-max-content;
  width: max-content;
  max-width: 400px;
  background-color: #233038;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 48px;

}


@media only screen and (max-width: 1100px) {
  .tooltipped-button .tooltiptext {
    max-width: 325px;
  }
}

@media only screen and (max-width: 1050px) {
  .tooltipped-button .tooltiptext {
    max-width: 400px;
  }
}

@media only screen and (max-width: 950px) {
  .tooltipped-button .tooltiptext {
    max-width: 350px;
  }
}

@media only screen and (max-width: 850px) {
  .tooltipped-button .tooltiptext {
    max-width: 300px;
  }
}

@media only screen and (max-width: 775px) {
  .tooltipped-button .tooltiptext {
    max-width: 275px;
  }
}

@media only screen and (max-width: 700px) {
  .tooltipped-button .tooltiptext {
    max-width: 350px;
  }
}

@media only screen and (max-width: 650px) {
  .tooltipped-button .tooltiptext {
    max-width: 300px;
  }
}

@media only screen and (max-width: 525px) {
  .tooltipped-button .tooltiptext {
    max-width: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .tooltipped-button .tooltiptext {
    max-width: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .tooltipped-button .tooltiptext {
    max-width: 300px;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipped-button:hover .tooltiptext {
  visibility: visible;
}

.tooltipped-button .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.paginate_button {
  margin-right: 8px;
}

.tooltip-form {
  position: relative;
}

.tooltip-form .tooltip-wrapper {
  position: absolute;
  top: 2px;
  left: 176px;
  z-index: 1;
}

.tooltip-wrapper p {
  font-size: 12px;
  font-weight: 800;
  background-color: #eef6fc;
  color: #1d72aa;
  padding: 2px 10px;
  border-radius: 100%;
  margin: 0;

}

.tooltip-wrapper .tooltiptext {
  left: 32px;
  top: -8px;
}

.tooltip-wrapper .tooltiptext::after {
  top: 20px;
  left: -5px;
  content: " ";
  position: absolute;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;

}

.location-buttons {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.location-buttons path {
  color: #a7c7df;
}

.location-buttons svg:hover path {
  color: #1d72aa;
}

.tooltip-form .label path,
.tooltip-form .dropdown-trigger path {
  background-color: #eef6fc;
  color: #1d72aa;
}

.tooltip-form .dropdown-content {
  padding: 0;
}

.tooltip-form .dropdown-item {
  width: -webkit-max-content !important;
  width: max-content !important;
  max-width: 400px !important;
  height: auto !important;
  padding: 4px 8px !important;
  font-size: 16px !important;
  background-color: #233038;
  color: #fff;
  font-weight: 400;
  border-radius: 8px;
}

.tooltip-form .dropdown-item::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 15px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-form .dropdown-item p,
.tooltip-form .label p {
  margin: 0;
}

.tooltip-form label.checkbox {
  margin-right: 8px;
}

.tooltip-form:has(label.checkbox) .dropdown-item {
  width: -webkit-max-content !important;
  width: max-content !important;
  max-width: 400px !important;
  height: auto !important;
  padding: 4px 8px !important;
  font-size: 16px !important;
  background-color: #233038;
  color: #fff;
  font-weight: 400;
  border-radius: 8px;
  margin-left: -8px;
}

.column.is-half .select {
  margin-bottom: 16px;
}

.field .location-buttons {
  margin-bottom: 16px;
}

.sticky {
  z-index: 11 !important;
}
.current-shift-list-header {
    background-color: hsl(204, 86%, 53%);
}

.inactive-list-header {
    background-color: gray;
}

.pointer {
    cursor: pointer;
    text-decoration: underline;
    color: hsl(204, 86%, 53%);
}

.table {
    width: 100%;
}

.shift-modal-card-card {
    top: 20%;
}

/* TO REMOVE AFTER MERGING REWORK_UI_2024*/

/* Tooltip container */
.tooltipped-button {
    position: relative;
    /* z-index:10; */
}

.tooltipped-button .tooltiptext {
    visibility: hidden;
    width: -webkit-max-content;
    width: max-content;
    max-width: 400px;
    background-color: #233038;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 48px;

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipped-button:hover .tooltiptext {
    visibility: visible;
}

.tooltipped-button .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

/* CSS FOR MOBILE AND OTHER FIXES */

/* HOME PAGE */

.next-shift-box{
    background: rgba(0, 0, 0, 0.16);
    color: white;
    padding: 16px;
    border-radius: 4px;
    border-left: 1px solid rgba(0, 0, 0, 0.24);
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.clock-in {
    background-color: white;
    color: hsl(217 71% 53%);
    border: 1px solid white;
    padding: 8px 16px;
    margin: 24px 0 0 0;
    border-radius: 4px;
    display: flex;
    transition-duration: 0; /*overriding a property*/
}

.clock-in:hover {
    border: 1px solid #274584;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.24);
    color: #3298dc;
}


@media only screen and (min-width: 600px) {
    .home-header {
        padding: 40px 48px;
        grid-gap: 32px;
        gap: 32px
    }

}

@media only screen and (min-width: 800px) {
    .home-header {
        grid-template-columns: 1fr 1fr;
        align-items: start;
    }
}

/* main part */

.home-section{
    padding: 24px;
}

.section-title-group .shift-pool-image {
    height: 3em;
    padding: 0;
    margin-right: 16px;
}

.section-title{
    margin: 0 0 24px 0!important;
    font-size: 2.5em;
    font-family: Roboto;
}

.shift-block{
    display: grid;
    grid-template-columns: minmax(auto, 80px) 1fr;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    margin-bottom: 24px;
}

.shift-block:last-of-type{
    margin: 0;
}

.pagination-is-rouned{
    margin-top: 32px !important;
}

.shift-date > p {
    text-align: center;
    color: darkgray;
}

.upcoming-shift-text:last-of-type{
    margin-bottom: 16px;
}

.shift-controls{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
    align-items: flex-start;
}

.button.is-info:hover{
    background-color: hsl(217 71% 53%);
}

@media only screen and (min-width: 600px) {
    .home-main {
        padding: 16px 24px;
    }
}

@media only screen and (min-width: 800px) {
    .home-main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: start;
        grid-gap: 24px;
        gap: 24px;
    }
}

/* SCHEDULE PAGE */

.container{
    padding: 24px;
    width: 100%;
    max-width: 100%;
}
/* overriding bulma */
.container:not(.is-max-desktop){
    max-width: 100%;
}
/* overriding bulma */
.container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1500px;
}

.container .title{
    margin: 0 0 24px 0;
}

.schedule-block{
    margin: 0;
}

.full-calendar{
    margin: 0;
    display: grid;
    grid-gap: 24px;
    gap: 24px;
}

.legend-groups{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 16px;
    grid-gap: 24px;
    gap: 24px;
    width:100%;
}

.legend-groups h3{
    color: #363636;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
}

.legend-groups div div{
    margin-bottom: 8px;
}

.calendar{
    overflow: scroll;
}

.field button{
    color: white;
    background-color: #2C3E50;
}

.field button:hover{
    background-color: #79828B;
    color: white;
}

.calendar .card-content{
    padding: 0;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    row-gap: 8px;
}

.fc-scrollgrid-sync-inner{
    text-align: center;
}

.fc-toolbar-chunk:nth-of-type(2){
    grid-row: 1/2;
    text-align: center;
}

.fc-toolbar-chunk:first-of-type{
    margin: auto;
}

.fc-toolbar-chunk:last-of-type{
    margin: auto;
}

@media only screen and (min-width: 400px) {
    .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
        grid-template-columns: 1fr 1fr;
    }
    
    .fc-toolbar-chunk:nth-of-type(2){
        grid-column: 1/3;
    }
    
    .fc-toolbar-chunk:first-of-type{
        justify-self: start;
        margin-left: 0;
    }
    
    .fc-toolbar-chunk:last-of-type{
        justify-self: end;
        margin-right: 0;
    }
}

@media only screen and (min-width: 550px) {
    .container .title {
        margin: 0 0 48px 0;
    }
    .legend {
      grid-row: 1;
    }


    /* .full-calendar{
        grid-template-columns: 136px 1fr;
    } */
}

@media only screen and (min-width: 800px) {
    .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
        grid-template-columns: auto 1fr auto;
    }
    
    .fc-toolbar-chunk:nth-of-type(2){
        grid-column: 2/3;
    }
    .legend-groups{
        grid-template-columns: 1fr;
    }
    .full-calendar{
        grid-template-columns: 136px 1fr;
    }
}

@media only screen and (min-width: 936px) {
    .container{
        padding: 48px;
    }

    .schedule-block{
        padding-bottom: 24px;
    }
}


@media only screen and (min-width: 1024px) {
    .full-calendar{
        grid-template-columns: 152px 1fr;
    }
}

/* MANAGE SCHEDULE */

.field {
    margin-bottom: 16px;
}

.field:not(:last-child) {
    margin-bottom: 16px;
}

.field.is-grouped {
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 32px;
    align-items: center;
}

h2.title {
    font-size: 1.7rem;
}

@media only screen and (min-width: 550px){
    .container h2.title {
        margin: 0 0 24px 0;
    }
}

/* EMPLOYEES */

.help-message{
    font-style: italic;
    margin-bottom: 16px;
    font-size: 12px;
}


.field.is-grouped>.control:not(:last-child){
    margin: 0;
}

.dataTables_length{
    margin-bottom:8px;
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
}

th {
    background-color: hsl(217, 71%, 53%);
}

.table thead tr .sorting:nth-of-type(1) {
    width: 71.9531px !important;
}

.table thead tr .sorting:nth-of-type(2) {
    width: 134.234px !important;
}

.table thead tr .sorting:nth-of-type(3) {
    width: 55.625px !important;
}

.table thead tr .sorting:nth-of-type(4) {
    width: 189.203px !important;
}

.table thead tr .sorting:nth-of-type(5) {
    width: 60.8438px !important;
}

.table thead tr .sorting:nth-of-type(6) {
    width: 51.1719px !important;
}

.table thead tr .sorting:nth-of-type(7) {
    width: 89.1406px !important;
}


.dataTables_scrollHeadInner, table.is-striped.dataTable.no-footer{
    width: 100% !important;
    min-width: 922.09px !important;
    max-width: none;
}

.table thead tr td, .table thead tr th {
    border-width: 0 0 2px;
    color: white;
    border-right: 1px solid rgba(255, 255, 255, 0.146);
}

table.dataTable>thead .sorting:after, 
table.dataTable>thead .sorting_asc:after, 
table.dataTable>thead .sorting_desc:after, 
table.dataTable>thead .sorting_asc_disabled:after, 
table.dataTable>thead .sorting_desc_disabled:after {
    right: 0.2em;
    content: "↓";
}

table.dataTable>thead .sorting:before, table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:before, table.dataTable>thead .sorting_desc_disabled:after {
    position: absolute;
    top: 8px;
    opacity: .3;
}


@media only screen and (min-width: 550px){
    div.dataTables_wrapper div.dataTables_filter {
        text-align: right;
    }
    .dataTables_length {
        float: left;
    }
}

@media only screen and (min-width: 1020px){
    .help-message{
        display: none;
    }
}


/* PROFILE PAGE */

label.checkbox {
    margin-right: 16px;
}

label>.input{
    margin-bottom: 16px;
    margin-top: 8px;
}

h3.label{
    font-size:1.3em;
}

form .columns .column.is-3, .column.is-4{
    padding: 0 16px;
}

.control.profile{
    margin-bottom: 16px;
}

.title.profile {
    display: inline-block;
    margin: 0;
}

.profile-header{
    margin: 0 0 24px 0;
}

.radio>input{
    margin-left: 8px;
}

.radio, .radio+.radio {
    margin: 0 16px 0 0;
}

/* HOURS PAGE */

.container .container{
    padding: 0;
}

/* ADMIN PAGE */

.checkbox input{
    margin-right: 8px;
}

.label svg{
    margin-left: 8px;
}

.card{
    margin-bottom: 16px;
}
.home-panel {
  background: linear-gradient(
    117deg in hsl,
    hsl(217 92% 53%) 22% 22%, hsl(190 100% 87%) 107% 107%
  );;
  padding: 24px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  color: white;
}

.left {
    margin: 0 24px;
}

.right {
    margin: 0 24px;
}

.indicator {
  position: -webkit-sticky;
  position: sticky;
  bottom: 24px;
  -webkit-animation: bounce 2s infinite;
          animation: bounce 2s infinite;
  color: pink;
  text-decoration: underline;
  font-weight: bold;
}

/* Bouncing animation */
@-webkit-keyframes bounce {

    0%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
}
@keyframes bounce {

    0%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
}

@media only screen and (max-width: 768px) {
    .indicator {
      display: none;
    }
  }
.loader {
    width: 60px;
    height: 60px;
    top: 20%;
    left: 45%;
    border: 10px solid lightgrey;
    border-bottom-color: hsl(217, 71%, 53%);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
}

#pdf-viewer {
    margin: 0 auto;
    width: 100%;
    height: 850px;
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.spacer {
    margin: auto 5px;
}
/*SMALL SIGN (416x624)*/

#sign-container {
    height: 416px;
    width: 624px;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
}

#sign-container p, #sign-container h1, #sign-container h2, #sign-container h3 {
    font-weight: 700;
    line-height: 1.25em;
}

#sign-container p  {
    font-size: 0.9em;
}

#sign-container h2 {
    font-size: 1.8em;
}

#sign-container h3 {
    font-size: 0.8em;
    letter-spacing: 0.25em;
}

.slim {
    font-weight: 300;
} 

#digital-sign-head {
    --top-color: #ffffff;
    --bottom-color: #ffffff;
    background-image: linear-gradient(var(--top-color), var(--bottom-color));
    --font-color: #e99b00;
    color: var(--font-color);
    width: 100%;
    height: 25%;
}

#digital-sign-body {
    --top-color: #e99b00;
    --bottom-color: #e5ba6f;
    background-image: linear-gradient(var(--top-color), var(--bottom-color));
    width: 100%;
    height: 75%;
    position: relative;
}

#head-content, #body-content {
    padding: 3% 4%;
}

#head-content h1 {
    font-size: 3em;
}

#body-content h1 {
    font-size: 2.6em;
}

#body-content #OH-shift {
    padding-top: 2.75em;
    display: inline-block;
}

#body-content #OH-shift h1 {
    font-size: 3.7em;
}

#body-content #OH-shift p {
    padding-top: 1em;
}

#current-shift {
    --main-color: #fefcf8;
    --secondary-color: #fefcf8;
    & .main {
        color: var(--main-color);
    }
    & .secondary {
        color: var(--secondary-color);
    }
}

#body-blob {
    padding-right: 14%;
}

#shifts {
    width: 100%;
    padding-top: 4%;
    display: flex;
    align-items: flex-start;
}

.staffer-bio {
    display: inline-block;
    width: 13%;
    margin-right: 1%;
}

.staffer-photo {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.staffer-bio p {
    text-align: center;
    overflow-wrap: break-word; 
    word-wrap: break-word; 
    word-break: break-word; 
}

.staffed-hours {
    display: inline-block;
    margin-left: 2%;
}

#next-shift {
    position: absolute;
    bottom: 0;
    height: 25%;
    --font-color: #f9deb5;
    color: var(--font-color);
}

/*LARGE SIGN (1080X192)*/

#sign-container-large {
    height: 1080px;
    width: 1920px;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
}

#sign-container-large p, #sign-container-large h1, #sign-container-large h2, #sign-container-large h3 {
    font-weight: 700;
    line-height: 1.25em;
}

#sign-container-large p  {
    font-size: 2.25em;
}

#sign-container-large h2 {
    font-size: 3.5em;
}

#sign-container-large h3 {
    font-size: 1.5em;
    letter-spacing: 0.25em;
}

#sign-container-large .slim {
    font-weight: 300;
} 

#sign-container-large #head-content, #sign-container-large #body-content {
    padding: 2% 3%;
}

#sign-container-large #head-content h1 {
    font-size: 8em;
}

#sign-container-large #body-content h1 {
    font-size: 7.5em;
}

#OH-shifts {
    width: 100%;
}

#OH-shifts #next-shift {
    display: inline-block;
    position: static;
    margin-left: 5%;
}

#staffers {
    padding-top: 2%;
    --main-color: #63196a;
    --secondary-color: #9634a2;
    width: 100%;
    & .main {
        color: var(--main-color);
    }
    & .secondary {
        color: var(--secondary-color);
    }
}

#OH-staffer-bios {
    display: flex;
    grid-gap: 60px;
    gap: 60px;
    padding-top: 1%;
}

.OH-staffer-bio {
    width: 180px;
}

.OH-staffer-photo {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

#staffers .OH-staffer-bio .staffer-name {
    font-weight: 300;
    font-size: 2em;
    text-align: center;
}

.placeholder-photo {
    --color: black;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--color);
}

.long-input {
  width: 500px;
}

.short-input {
  width: 300px;
}

/* flash */
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* modal */
.modal-ul {
  list-style: disc;
  padding: 10px;
}

#modal-root {
  position: fixed;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  opacity: 0;
  transition: opacity 0.15s ease-out, width 0s linear 0.15s, height 0s linear 0.15s;
}

#modal-root.visible {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Sandra CSS */

.container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.manage-group-wrapper {
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-items: stretch;
}

.container h1 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 32px;
}

@media only screen and (min-width: 1200px) {
  .manage-group-wrapper {
    width: 100%;
    grid-template-columns: 1fr, auto;
    grid-template-areas:
      "title title"
      "selection selection"
      "main edit ";
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    align-items: start;
  }

  .manage-group-wrapper>.group-section:first-of-type {
    grid-area: selection;
  }

  .container h1 {
    grid-area: title;
  }

  .main {
    grid-area: main;
  }

  .group-edit {
    grid-area: edit;
  }
}



.group-section {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  margin-bottom: 32px;
  justify-content: stretch;
  /* width: fit-content; */
}

.group-section h2 {
  font-size: 24px;
  font-weight: 600;
}

.group-section .field {
  margin: 0;
}

.group-border-box {
  padding: 16px;
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid rgba(211, 211, 211, 0.5);
  box-shadow: 1px 1px 10px rgba(211, 211, 211, 0.5);
}

.group-section h3 {
  font-size: 20px;
  font-weight: 600;
}

.group-section h4 {
  font-size: 16px;
  font-weight: 600;
}

.group-section p {
  max-width: 60ch;
}

.group-section form {
  display: grid;
  grid-gap: 8px;
  gap: 8px;
}

.group-section label {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.group-border-box .input#hourlyReminder,
.group-border-box .input.hours-restrictions {
  max-width: 50px;
}

.group-border-box .input.ipaddress {
  max-width: 100px;
}

.group-border-box .control {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.button.is-info:hover {
  background-color: #113650;
  color: #eef6fc;
}


.group-border-box .input.long-input {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 350px;
}

.group-edit-section {
  display: grid;
  grid-gap: 8px;
  gap: 8px;
}

.group-edit-section .field.is-grouped{
  max-width: 630px;
}

.group-edit .control {
  flex-wrap: wrap;
  flex-shrink: unset !important;
}

.group-edit-section label {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 600;
}

.group-edit-section label.checkbox {
  font-weight: 400;
}

.field.group-edit-section .location-buttons {
  margin-bottom: 0;
}

.button.primary.red {
  background-color: #f14668;
}

.tabs a{
  padding: 4px 8px;
}

.tabs ul {
  justify-content: space-between ;
}

#preferred-container p:empty ~ #preferred-hours{
  display: none;
}

#preferred-container .label:only-child { color: blue; }

.legend {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  align-content: start;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.legend h4 {
  font-size: 16px;
  font-weight: 600;
}

.legend .flex-holder {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
  flex-wrap: wrap;
}

.legend .flex-holder>div {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
.legend {
  grid-row: 2/3;
}
}
/* banner view */
.banner-container {    
  position: relative;
  overflow-x: scroll;
}

.banner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-bottom: thin solid #a8adb5;
}

.banner-row-time {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
  position: absolute;
  height: 100%;
}

.banner-times {
  text-align: left;
  text-indent: 5%;
  font-size: 15px;
  border-left: thin solid #a8adb5;
  flex: 1 1;
}

.banner-times:last-child {
  border-right: thin solid #a8adb5;
}

.banner-blocks {
  height: 100%;
  flex-shrink: 0;
}

.banner-blocks:nth-child(even) {
  border-radius: 10px;
}
