.home-panel {
  background: linear-gradient(
    117deg in hsl,
    hsl(217 92% 53%) 22% 22%, hsl(190 100% 87%) 107% 107%
  );;
  padding: 24px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  color: white;
}

.left {
    margin: 0 24px;
}

.right {
    margin: 0 24px;
}

.indicator {
  position: sticky;
  bottom: 24px;
  animation: bounce 2s infinite;
  color: pink;
  text-decoration: underline;
  font-weight: bold;
}

/* Bouncing animation */
@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }
}

@media only screen and (max-width: 768px) {
    .indicator {
      display: none;
    }
  }