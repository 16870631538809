.long-input {
  width: 500px;
}

.short-input {
  width: 300px;
}

/* flash */
.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

/* modal */
.modal-ul {
  list-style: disc;
  padding: 10px;
}

#modal-root {
  position: fixed;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  opacity: 0;
  transition: opacity 0.15s ease-out, width 0s linear 0.15s, height 0s linear 0.15s;
}

#modal-root.visible {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.15s ease-out;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Sandra CSS */

.container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.manage-group-wrapper {
  display: grid;
  width: fit-content;
  justify-items: stretch;
}

.container h1 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 32px;
}

@media only screen and (min-width: 1200px) {
  .manage-group-wrapper {
    width: 100%;
    grid-template-columns: 1fr, auto;
    grid-template-areas:
      "title title"
      "selection selection"
      "main edit ";
    column-gap: 24px;
    align-items: start;
  }

  .manage-group-wrapper>.group-section:first-of-type {
    grid-area: selection;
  }

  .container h1 {
    grid-area: title;
  }

  .main {
    grid-area: main;
  }

  .group-edit {
    grid-area: edit;
  }
}



.group-section {
  display: grid;
  gap: 24px;
  margin-bottom: 32px;
  justify-content: stretch;
  /* width: fit-content; */
}

.group-section h2 {
  font-size: 24px;
  font-weight: 600;
}

.group-section .field {
  margin: 0;
}

.group-border-box {
  padding: 16px;
  display: grid;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid rgba(211, 211, 211, 0.5);
  box-shadow: 1px 1px 10px rgba(211, 211, 211, 0.5);
}

.group-section h3 {
  font-size: 20px;
  font-weight: 600;
}

.group-section h4 {
  font-size: 16px;
  font-weight: 600;
}

.group-section p {
  max-width: 60ch;
}

.group-section form {
  display: grid;
  gap: 8px;
}

.group-section label {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0 !important;
  width: fit-content;
}

.group-border-box .input#hourlyReminder,
.group-border-box .input.hours-restrictions {
  max-width: 50px;
}

.group-border-box .input.ipaddress {
  max-width: 100px;
}

.group-border-box .control {
  display: flex;
  gap: 8px;
  align-items: center;
}

.button.is-info:hover {
  background-color: #113650;
  color: #eef6fc;
}


.group-border-box .input.long-input {
  width: fit-content;
  min-width: 350px;
}

.group-edit-section {
  display: grid;
  gap: 8px;
}

.group-edit-section .field.is-grouped{
  max-width: 630px;
}

.group-edit .control {
  flex-wrap: wrap;
  flex-shrink: unset !important;
}

.group-edit-section label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}

.group-edit-section label.checkbox {
  font-weight: 400;
}

.field.group-edit-section .location-buttons {
  margin-bottom: 0;
}

.button.primary.red {
  background-color: #f14668;
}

.tabs a{
  padding: 4px 8px;
}

.tabs ul {
  justify-content: space-between ;
}

#preferred-container p:empty ~ #preferred-hours{
  display: none;
}

#preferred-container .label:only-child { color: blue; }

.legend {
  display: grid;
  gap: 16px;
  align-content: start;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.legend h4 {
  font-size: 16px;
  font-weight: 600;
}

.legend .flex-holder {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.legend .flex-holder>div {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
.legend {
  grid-row: 2/3;
}
}