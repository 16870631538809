.current-shift-list-header {
    background-color: hsl(204, 86%, 53%);
}

.inactive-list-header {
    background-color: gray;
}

.pointer {
    cursor: pointer;
    text-decoration: underline;
    color: hsl(204, 86%, 53%);
}

.table {
    width: 100%;
}

.shift-modal-card-card {
    top: 20%;
}

/* TO REMOVE AFTER MERGING REWORK_UI_2024*/

/* Tooltip container */
.tooltipped-button {
    position: relative;
    /* z-index:10; */
}

.tooltipped-button .tooltiptext {
    visibility: hidden;
    width: max-content;
    max-width: 400px;
    background-color: #233038;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 48px;

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipped-button:hover .tooltiptext {
    visibility: visible;
}

.tooltipped-button .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
