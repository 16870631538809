/* banner view */
.banner-container {    
  position: relative;
  overflow-x: scroll;
}

.banner-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-bottom: thin solid #a8adb5;
}

.banner-row-time {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
  position: absolute;
  height: 100%;
}

.banner-times {
  text-align: left;
  text-indent: 5%;
  font-size: 15px;
  border-left: thin solid #a8adb5;
  flex: 1;
}

.banner-times:last-child {
  border-right: thin solid #a8adb5;
}

.banner-blocks {
  height: 100%;
  flex-shrink: 0;
}

.banner-blocks:nth-child(even) {
  border-radius: 10px;
}