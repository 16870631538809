.hide-form {
  display: none;
}

.card {
  overflow: visible;
}

.field.is-grouped:last-child {
  margin-bottom: 0;
}

/* Tooltip container */
.tooltipped-button {
  position: relative;
  /* z-index:10; */
}

/* Tooltip text */
.tooltipped-button .tooltiptext {
  visibility: hidden;
  width: max-content;
  max-width: 400px;
  background-color: #233038;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 48px;

}


@media only screen and (max-width: 1100px) {
  .tooltipped-button .tooltiptext {
    max-width: 325px;
  }
}

@media only screen and (max-width: 1050px) {
  .tooltipped-button .tooltiptext {
    max-width: 400px;
  }
}

@media only screen and (max-width: 950px) {
  .tooltipped-button .tooltiptext {
    max-width: 350px;
  }
}

@media only screen and (max-width: 850px) {
  .tooltipped-button .tooltiptext {
    max-width: 300px;
  }
}

@media only screen and (max-width: 775px) {
  .tooltipped-button .tooltiptext {
    max-width: 275px;
  }
}

@media only screen and (max-width: 700px) {
  .tooltipped-button .tooltiptext {
    max-width: 350px;
  }
}

@media only screen and (max-width: 650px) {
  .tooltipped-button .tooltiptext {
    max-width: 300px;
  }
}

@media only screen and (max-width: 525px) {
  .tooltipped-button .tooltiptext {
    max-width: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .tooltipped-button .tooltiptext {
    max-width: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .tooltipped-button .tooltiptext {
    max-width: 300px;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipped-button:hover .tooltiptext {
  visibility: visible;
}

.tooltipped-button .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.paginate_button {
  margin-right: 8px;
}

.tooltip-form {
  position: relative;
}

.tooltip-form .tooltip-wrapper {
  position: absolute;
  top: 2px;
  left: 176px;
  z-index: 1;
}

.tooltip-wrapper p {
  font-size: 12px;
  font-weight: 800;
  background-color: #eef6fc;
  color: #1d72aa;
  padding: 2px 10px;
  border-radius: 100%;
  margin: 0;

}

.tooltip-wrapper .tooltiptext {
  left: 32px;
  top: -8px;
}

.tooltip-wrapper .tooltiptext::after {
  top: 20px;
  left: -5px;
  content: " ";
  position: absolute;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;

}

.location-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.location-buttons path {
  color: #a7c7df;
}

.location-buttons svg:hover path {
  color: #1d72aa;
}

.tooltip-form .label path,
.tooltip-form .dropdown-trigger path {
  background-color: #eef6fc;
  color: #1d72aa;
}

.tooltip-form .dropdown-content {
  padding: 0;
}

.tooltip-form .dropdown-item {
  width: max-content !important;
  max-width: 400px !important;
  height: auto !important;
  padding: 4px 8px !important;
  font-size: 16px !important;
  background-color: #233038;
  color: #fff;
  font-weight: 400;
  border-radius: 8px;
}

.tooltip-form .dropdown-item::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 15px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-form .dropdown-item p,
.tooltip-form .label p {
  margin: 0;
}

.tooltip-form label.checkbox {
  margin-right: 8px;
}

.tooltip-form:has(label.checkbox) .dropdown-item {
  width: max-content !important;
  max-width: 400px !important;
  height: auto !important;
  padding: 4px 8px !important;
  font-size: 16px !important;
  background-color: #233038;
  color: #fff;
  font-weight: 400;
  border-radius: 8px;
  margin-left: -8px;
}

.column.is-half .select {
  margin-bottom: 16px;
}

.field .location-buttons {
  margin-bottom: 16px;
}

.sticky {
  z-index: 11 !important;
}